<template>
	<div class="header">
		<div class="navbar u-flex u-col-center">
			<div  class=" u-flex u-col-center">
				<div class="collapse" @click="changeNav">
					<el-icon v-if="collapse" class="el-icon-s-unfold"></el-icon>
					<el-icon v-else class="el-icon-s-fold"></el-icon>
				</div>
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item v-for="item in list">{{ item }}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>


			<div class=" u-flex u-col-center">
				<el-dropdown placement="bottom" @command="commandClick">
					<div class="user-box u-flex u-col-center">
						<span>{{ user.username }}</span>
						<img src="@/assets/img/down.png">
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="logout">退出登录</el-dropdown-item>
						</el-dropdown-menu>

					</div>
				</el-dropdown>
				<!-- <div class="role">
					{{ user.username }}
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import ThemePicker from '@/components/themePicker'
export default {
	name: "uHeader",
	components: {
		ThemePicker: ThemePicker
	},
	data() {
		return {
			list: [],
			uploadUrl: '',
			unread_number: 0,
			rolekey: '',
			notice: [],
			testRole:[],
			testToken:''
		}
	},
	computed: {
		collapse() {
			return this.$store.state.collapse
		},
		navbar() {
			return this.$store.state.$navbar || []
		},
		unReadNum() {
			return this.$store.state.unReadNum || 0
		},
		user() {
			return this.$store.state.userInfo || {}
		}
	},
	watch: {
		'$route'(val) {
			this.list = this.$route.meta.name ? this.$route.meta.name.split(',') : []
		}
	},
	created() {
		this.uploadUrl = this.$http.defaults.baseURL + '/backend/upload'
		this.list = this.$route.meta.name ? this.$route.meta.name.split(',') : []
		let vuex = localStorage.getItem('vuex')
	},
	methods: {
		commandClick(e) {
			if (e === 'logout') {
				this.$store.commit('logout')
				setTimeout(() => {
					this.$router.replace('/login')
					location.reload()
				}, 800)
			}
			if (e === 'user') {
				this.$router.replace('/userInfo')
			}
		},
		changeNav() {
			this.$store.commit('setCollapse', !this.$store.state.collapse)
		},
		removeNavBar(path) {
			this.$store.commit('removeNavBar', path)
			let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
				.$navbar.length - 1].path
			this.openPage(url)
		}
	}
}
</script>

<style lang="scss" scoped>
.header {
	background-color: #fff;
	box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.08);
	position: relative;
	z-index: 1;

	/deep/.el-badge__content {
		line-height: 16px;
	}

	.navbar {
		height: 50px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.notice_message {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 20%;
			height: 38px;
			transform: translate(-50%, -50%);
			border-radius: 10px;
			overflow: hidden;

			.notice_message_item {
				width: 100%;
				height: 100%;
				background-color: #FFF8ED;
				display: flex;
				align-items: center;
				padding: 0 30px;
				cursor: pointer;

				.icon_img {
					width: 25px;
					height: 25px;
					margin-right: 30px;
				}

				.message_txt {
					flex: 1;
					width: 0;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 12px;
					font-weight: bold;
					color: #FF7C1E;
				}
			}
		}

		.collapse {
			padding: 0 15px;
			cursor: pointer;

			i {
				vertical-align: sub;
				font-size: 20px;
			}
		}

		.el-breadcrumb {

			.el-breadcrumb__separator,
			.el-breadcrumb__inner.is-link {
				font-weight: normal;
			}
		}
	}

	.tags-view {
		background-color: #fff;

		.tags-view-wrap {
			padding-bottom: 6px;
			margin: 0 20px;

			.tags-item {
				flex-shrink: 0;
				height: 28px;
				border: 1px solid #d8dce5;
				color: #495060;
				background: #fff;
				padding: 0 8px;
				font-size: 12px;
				margin: 4px 0 0 4px;
				cursor: pointer;

				&.current {
					background-color: #409eff;
					color: #fff;
					padding-left: 20px;
					position: relative;

					&:after {
						content: '';
						background: #fff;
						position: absolute;
						width: 8px;
						height: 8px;
						border-radius: 50%;
						top: 50%;
						margin-top: -4px;
						left: 8px;
					}
				}

				.close-icon {
					width: 16px;
					height: 16px;
					border-radius: 50%;
					line-height: 16px;
					text-align: center;
					margin-left: 4px;

					&:hover {
						background-color: #b4bccc;
						color: #fff;
					}

					.el-icon-close {
						font-size: 12px;
						transform-origin: center;
						transform: scale(0.8);

						:before {
							vertical-align: middle;
						}
					}
				}
			}
		}
	}
}

.role {
	margin-right: 10px;
	color: #409eff;
}

.notice-box {
	margin-left: auto;
	position: relative;
	cursor: pointer;

	span {
		width: 14px;
		height: 14px;
		background: #FF4949;
		position: absolute;
		color: #fff;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		border-radius: 50%;
		top: -4px;
		right: -4px;
	}
}

.user-box {
	margin: 0 24px 0 32px;
	cursor: pointer;

	span {
		margin: 0 8px 0 12px;
	}
	.avatar{
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}
}
</style>
