<template>
	<div class="page u-flex">
		<el-scrollbar class="u-row-nowrap" style="height: calc(100vh - 50px); width:100%">
			<div class="right">
				<el-form :model="searchForm" class="search-form" inline>
					<el-form-item label="登录名称：">
						<el-input v-model="searchForm.username" placeholder="请输入用户名称" size="small"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
							@click="searchTable('search')">搜索
						</el-button>
						<el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small"
							type="primary" @click="searchTable('reset')">重置
						</el-button>
					</el-form-item>
				</el-form>
				<div class="operate-box u-flex u-col-center">
					<el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
						@click="create">新增
					</el-button>
				</div> 
				<el-table v-loading="loading" :data="tableData"
					:tree-props="{ children: 'child', hasChildren: 'hasChildren' }" row-key="id" style="width: 100%;"
					header-row-class-name="header-table">
					<el-table-column label="用户编号" prop="_id" align="center" width="250">
					</el-table-column>
					<el-table-column label="登录名称" prop="username" align="center">
					</el-table-column>
					<el-table-column label="登录密码" prop="password" align="center">
					</el-table-column>
					<el-table-column label="创建时间" prop="createTime" align="center">
					</el-table-column>
					<el-table-column label="操作" width="150" fixed="right">
						<template slot-scope="scope">
							<span class="o-btn" @click="edit(scope.row)">
								<el-icon class="el-icon-edit"></el-icon>
								编辑
							</span>
							<span class="o-btn o-btn-red"
								@click="remove(scope.row._id)">
								<el-icon class="el-icon-delete"></el-icon>
								删除
							</span>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-flex u-row-end u-p-t-24 u-p-b-24">
					<el-pagination :current-page="page" :page-size="size" :total="total"
						layout="total,prev, pager, next, jumper" @current-change="changePage">
					</el-pagination>
				</div>
			</div>
		</el-scrollbar>
		<el-dialog :close-on-click-modal="false" :title="mode === 'add' ? '添加用户' : '编辑用户'" :visible.sync="dialogVisible"
			destroy-on-close width="750px" @closed="resetCreateData">
			<div>
				<el-form ref="createForm" :model="createData" :rules="createRules" class="create-form" label-width="80px">
					<el-row>
						<el-col :span="12">
							<el-form-item label="登录名称" prop="username">
								<el-input v-model="createData.username" size="small"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="用户密码" prop="password">
								<el-input v-model="createData.password" show-password size="small" type="password">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="u-custom-btn-primary" type="primary" @click="createMenu">确 定</el-button>
				<el-button @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "uMenu",
	data() {
		return {
			page: 1,
			size: 10,
			total: 0,
			mode: 'add',
			loading: false,
			dialogVisible: false,
			id: '',
			treeList: [],
			roleList: [],
			createData: {
				username:"",
				password:''
			},
			createRules: {
				username: {
					required: true,
					message: '请输入用户名称',
					trigger: 'change'
				},
				password: {
					required: true,
					message: '请输入登录密码',
					trigger: 'change'
				}
			},
			searchForm: {
				username:''
			},
			department:[],
			tableData: [],
			struct_ids: []
		}
	},
	created() {
		this.getData()
	},
	methods: {
		searchTable(t) {
			this.page = 1
			if (t === 'search') {
				this.getData()
			} else {
				this.searchForm = {
					username: '',
				}
				this.getData()
			}
		},
		edit(e) {
			this.id = e._id
			this.mode = 'edit'
			this.createData.username = e.username
			delete this.createRules.password
			this.dialogVisible = true
		},
		create() {
			this.mode = 'add'
			this.createRules.password = {
				required: true,
				message: '请输入登录密码',
				trigger: 'change'
			}
			this.dialogVisible = true
		},
		remove(id) {
			this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let data = {
					id
				}
				this.$http.post('/backend/role_user/delete', data).then(res => {
					if (res.code === 1) {
						this.$message.success('删除成功')
						this.getData()
					}
				})
			})

		},
		resetCreateData() {
			this.createData = {
				username:'',
				password:''
			}
			this.$refs['createForm'].resetFields()
		},
		createMenu() {
			if (this.mode === 'add') {
				this.$refs['createForm'].validate(valid => {
					if (valid) {
						this.$http.post('/admin/users/register', this.createData).then(res => {
							if (res.code === 1) {
								this.dialogVisible = false
								this.getData()
								this.$message.success("操作成功")
							}
						})
					}
				})
			} else {
				if (!this.createData.password) {
					delete this.createData.password
				}
				this.$refs['createForm'].validate(valid => {
					if (valid) {
						this.createData.id = this.id
						this.$http.post('/admin/users/update', this.createData).then(res => {
							if (res.code === 1) {
								this.dialogVisible = false
								this.getData()
								this.$message.success("操作成功")
							}
						})
					}
				})
			}

		},
		getData() {
			this.loading = true
			this.searchForm.page = this.page
			this.$http.get('/admin/users/index', {
				params: this.searchForm
			}).then(res => {
				if (res.code === 1) {
					this.tableData = res.data.data
					this.total = res.data.total
				}
				this.loading = false
			})
		},
		changePage(e) {
			this.page = e
			this.getData()
		}
	}
}
</script>

<style lang="scss" scoped>
.page {
	height: calc(100vh - 50px);
	overflow: hidden;
}

.left {
	min-height: calc(100vh - 50px);
	width: 240px;
	background: #fff;
	padding: 10px;

	.el-tree {
		padding-bottom: 20px;
	}
}

.right {
	min-height: calc(100vh - 50px);
	padding: 20px;
	flex: 1;
	overflow: hidden;
}

.el-table {
	margin-top: 24px;

	.o-btn {
		color: #409EFF;
		cursor: pointer;

		~.o-btn {
			margin-left: 12px;
		}
	}
}

.create-form {
	.el-form-item {
		margin-bottom: 20px;
	}
}
</style>
