import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

/*------------公共页面------------------------ */
import Login from "@/views/login";
import Home from "@/views/home";
import About from "@/views/about";
import subject from "@/views/questionbankManage/subject";
import school from "@/views/questionbankManage/school";
import simulation from "@/views/questionbankManage/simulation";
import truequestion from "@/views/questionbankManage/truequestion";
import unit from "@/views/questionbankManage/unit";
import unitIndex from "@/views/questionbankManage/unitIndex";

import user from "@/views/system/user";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      name: "轮播图管理",
      keepAlive: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      name: "登录",
      keepAlive: false,
    },
  },
  {
    path: "/subject",
    name: "subject",
    component: subject,
    meta: {
      name: "科目管理",
      keepAlive: false,
    },
  },
  {
    path: "/school",
    name: "school",
    component: school,
    meta: {
      name: "学校管理",
      keepAlive: false,
    },
  },
  {
    path: "/simulation",
    name: "simulation",
    component: simulation,
    meta: {
      name: "模拟练习",
      keepAlive: false,
    },
  },
  {
    path: "/unit",
    name: "unit",
    component: unit,
    meta: {
      name: "单元测试",
      keepAlive: false,
    },
  },
  {
    path: "/unitIndex",
    name: "unitIndex",
    component: unitIndex,
    meta: {
      name: "章节管理",
      keepAlive: false,
    },
  },
  
  {
    path: "/truequestion",
    name: "truequestion",
    component: truequestion,
    meta: {
      name: "真题实战",
      keepAlive: false,
    },
  },
  {
    path: "/about",
    name: "about",
    component: About,
    meta: {
      name: "关于我们",
      keepAlive: true,
    },
  },
  {
    path: "/user",
    name: "user",
    component: user,
    meta: {
      name: "人员管理",
      keepAlive: true,
    },
  },
  
];

const router = new VueRouter({
  routes,
  scrollBehavior: function (to, from, savedPosition) {},
});
import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({
  showSpinner: false,
  easing: "ease",
  speed: 500,
});

const whiteList = ["/login", "/maintain","/wxApplet"];
// 注册全局钩子用来拦截导航
router.beforeEach((to, from, next) => {
  let list = JSON.parse(localStorage.getItem("$navbar")) || [];
  let data = list.find((item) => item.path === to.path);
  to.meta.keepAlive = !(
    data === undefined ||
    to.path === "/" ||
    to.path === "/home"
  );
  NProgress.start();
  if (localStorage.getItem("token")) {
    // 判断是否有token
    if (to.path === "/login") {
      next({
        path: "/",
      });
    } else {
      // console.log(store.getters)
      next();
      // if (store.getters.roles.length === 0) { // 判断当前用户是否已拉取完user_info信息
      // 	next()
      // 	// store.dispatch('GetInfo').then(res => { // 拉取info
      // 	//   const roles = res.roles;
      // 	//   // console.log('roles3')
      // 	//   // console.log(roles)
      // 	//   store.dispatch('GenerateRoutes', {
      // 	//     roles
      // 	//   }).then(() => { // 生成可访问的路由表
      // 	//     // console.log(store.getters.addRouters)
      // 	//     router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
      // 	//     next({ ...to,
      // 	//       replace: true
      // 	//     }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
      // 	//   })
      // 	// }).catch(err => {
      // 	//   console.log(err);
      // 	// });

      // } else {
      // 	next() //当有用户权限的时候，说明所有可访问路由已生成 如访问没权限的全面会自动进入404页面
      // }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next("/login"); // 否则全部重定向到登录页
    }
  }
});
router.afterEach((to, from, next) => {
  NProgress.done();
});

// router.beforeEach((to, from, next) => {
//     let list = JSON.parse(localStorage.getItem('$navbar')) || []
//     let data = list.find(item => item.path === to.path)
//     to.meta.keepAlive = !(data === undefined || to.path === '/' || to.path === '/home')
//     nprogress.start()
//     if(to.path !== '/login' && localStorage.getItem('token')){
//         store.dispatch('updateUnReadMsg')
//     }
//     if(to.path !== '/login'){
// 		console.log(123)
// 		console.log(white.indexOf(to.path) === -1)
// 		let white = ['/system/maintain']
// 		if(white.indexOf(to.path) === -1) {
// 			console.log(to.path)
// 		    next(to.path);
// 		    return
// 		}

//         if(!localStorage.getItem('token')){
//             next({
//                 path: '/login',
//                 // query: {
//                 //     redirect: to.path
//                 // }
//             })
//             return
//         }
//         // let white = {
//         //     system: ['/order/preview','/student/create','/system/maintain'],
//         //     school: ['/order/preview','/order/create','/order/edit','/order/sub','/system/maintain'],
//         //     bstore: ['/order/preview','/system/maintain']
//         // }
//         // if(!store.state.userInfo.role_menu.some(item => item.url === to.path) && white[store.state.userInfo.type].indexOf(to.path) === -1) {
//         //     next(from.path)
//         //     nprogress.done()
//         //     return
//         // }

//     } else {
//         if (localStorage.getItem('token')) {
//             next(from.path)
//             return
//         }
//     }
//     next()
// })
// router.afterEach((to) => {
//     // window.scrollTo(0, 0)
//     nprogress.done()
// })
export default router;
