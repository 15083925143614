import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from "vue-router"
import http from '@/utils/request'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)
export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.localStorage,
        // reducer(val) {
        //     return {
        //         userInfo: val.userInfo
        //     }
        // }
    })],
    state: {
        token: '',
        userInfo: {},
        collapse: false,
        $navbar: [],
        cachePageName: '',
        type: 'school',
        unReadNum: 0,
        menuList: [],
    },
    mutations: {
        setToken(state, data) {
            state.token = data
            localStorage.setItem('token', data)
        },
        setCollapse(state, data) {
            state.collapse = data
        },
        setActivePath(state, data) {
            state.activePath = data
        },
        setNavBar(state, data) {
            state.$navbar = data
            localStorage.setItem('$navbar', JSON.stringify(data))
        },
        removeNavBar(state, data) {
            let index = state.$navbar.findIndex(v => v.path === data)
            state.$navbar.splice(index, 1)
            localStorage.setItem('$navbar', JSON.stringify(state.$navbar))
        },
        setUnReadNum(state, data) {
            state.unReadNum = data
        },
        logout(state){
            state.token = ''
            state.userInfo = {}
            state.collapse = false
            state.$navbar = []
            state.unReadNum = 0
            localStorage.removeItem('token')
            localStorage.removeItem('$navbar')
            localStorage.removeItem('vuex')
        },
        setUserInfo(state,data){
            state.userInfo = data
        }
    },
    actions: {
        updateUnReadMsg(context,e) {
            http.get(e).then(res => {
                if (res.code === 1) {
                    context.commit('setUnReadNum', res.data.unread_number)
                }
            })
        },
        updateUserInfo(context){
           http.get('/admin/users/userInfo').then(res => {
                if(res.code === 1){
                    context.commit('setUserInfo',res.data.info)
                }
            })
        }
    },
    modules: {}
})
