<template>
	<div class="page u-flex">
		<el-scrollbar class="u-row-nowrap" style="height: calc(100vh - 50px); width:100%">
			<div class="header u-flex u-row-between u-col-center">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item>{{ isSubjectName }}</el-breadcrumb-item>
					<el-breadcrumb-item>{{ chapterName }}</el-breadcrumb-item>
					<el-breadcrumb-item>题库管理</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="right">
				<el-form :model="searchForm" class="search-form" inline>
					<el-form-item label="题目内容：">
						<el-input v-model="searchForm.content" placeholder="请输入题目内容"></el-input>
					</el-form-item>
					<el-form-item label="选择科目:">
						<el-select v-model="searchForm.subjectCategory" placeholder="请选择科目" >
							<el-option v-for="item in subject" :key="item._id" :label="item.name" :value="item._id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button class="u-custom-btn-primary" icon="el-icon-search" type="primary"
							@click="searchTable('search')">搜索
						</el-button>
						<el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain 
							type="primary" @click="searchTable('reset')">重置
						</el-button>
					</el-form-item>
				</el-form>
				<div class="operate-box u-flex u-col-center">
					<el-button class="u-custom-btn-primary" icon="el-icon-plus" type="primary"
						@click="create">新增
					</el-button>
				</div>
				<el-table v-loading="loading" :data="tableData"
					:tree-props="{ children: 'child', hasChildren: 'hasChildren' }" row-key="id" style="width: 100%;"
					header-row-class-slot="header-table">
					<el-table-column type="index" width="55" align="center">
					</el-table-column>
					<el-table-column label="科目" prop="subjectCategoryName" width="200px">
					</el-table-column>
					<el-table-column label="题目类型" prop="questionType" width="100px">
						<template slot-scope="scope">
							<span v-if="scope.row.questionType">图片</span>
							<span v-else>文字</span>
						</template>
					</el-table-column>
					<el-table-column label="题目内容" prop="content">
						<template slot-scope="scope">
							<div v-if="!scope.row.questionType">
								<el-popover placement="top-start" width="600" trigger="hover" :content="scope.row.content">
									<div class="w_400_eliscpe" slot="reference">{{ scope.row.content || '' }}</div>
								</el-popover>
							</div>
							<div v-else>
								<el-image style="width: 100px; height: auto" :src="scope.row.content" fit="contain"
									:preview-src-list="[scope.row.content]">
								</el-image>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="选项类型" prop="questionFormat" width="100px">
						<template slot-scope="scope">
							<span v-if="scope.row.questionFormat=='0'">单选</span>
							<span v-if="scope.row.questionFormat=='1'">多选</span>
							<span v-if="scope.row.questionFormat=='2'">计算题</span>
							<span v-if="scope.row.questionFormat=='3'">填空题</span>
							<span v-if="scope.row.questionFormat=='4'">简答题</span>
						</template>
					</el-table-column>
					<el-table-column label="题目解析" prop="explanation">
						<template slot-scope="scope">
							<el-popover placement="top-start" width="600" trigger="hover">
								<div v-html="scope.row.explanation"></div>
								<div class="w_400_eliscpe" slot="reference" v-html="scope.row.explanation"></div>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column label="创建时间" prop="createTime" width="200px">
					</el-table-column>
					<el-table-column label="操作" width="150" fixed="right">
						<template slot-scope="scope">
							<span class="o-btn" @click="edit(scope.row)">
								<el-icon class="el-icon-edit"></el-icon>
								编辑
							</span>
							<span class="o-btn o-btn-red" @click="remove(scope.row._id)">
								<el-icon class="el-icon-delete"></el-icon>
								删除
							</span>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-flex u-row-end u-p-t-24 u-p-b-24">
					<el-pagination :current-page="page" :page-size="size" :total="total"
						layout="total,prev, pager, next, jumper" @current-change="changePage">
					</el-pagination>
				</div>
			</div>
		</el-scrollbar>
		<el-dialog :close-on-click-modal="false" :title="mode === 'add' ? '添加题目' : '编辑题目'" :visible.sync="dialogVisible"
			destroy-on-close width="1100px" @closed="resetCreateData" top="10vh">
			<div style="padding:0 20px;">
				<el-form ref="createForm" :model="createData" :rules="createRules" class="create-form" label-width="130px">

					<el-row type="flex" class="row-bg" justify="space-between">
						<el-col :span="11">
							<el-form-item label="选择科目:" prop="subjectCategory">
								<el-select v-model="createData.subjectCategory" disabled style="width: 100%;">
									<el-option v-for="item in subject" :key="item._id" :label="item.name" :value="item._id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="11">
							<el-form-item label="章节:" prop="chapter_id">
								<el-input placeholder="请输入内容" v-model="chapterName" :disabled="true">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item label="题目类型:" prop="questionType">
						<el-switch v-model="createData.questionType" active-text="图片" inactive-text="文字" size="medium"
							@change="questionTypeChange">
						</el-switch>
					</el-form-item>
					<el-form-item label="请输入文字题目:" prop="content" v-if="!createData.questionType">
						<el-input type="textarea" :rows="5" placeholder="请输入文字题目内容" v-model="createData.content">
						</el-input>
					</el-form-item>
					<el-form-item label="请上传图片题目:" prop="content" v-else>
						<el-image v-if="createData.content" style="width:600px; height: auto" :src="createData.content"
							:preview-src-list="[createData.content]">
						</el-image>
						<ImgCutter @cutDown="cutDown" :boxHeight="800" :boxWidth="800" :cutWidth="280" :cutHeight="96"
							:sizeChange="true"></ImgCutter>
					</el-form-item>
					<el-form-item label="选项类型:" prop="questionFormat">
						<el-select v-model="createData.questionFormat" placeholder="请选择" @change="questionFormatChange">
							<el-option v-for="item in questionFormatoptions" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="题目选项:" prop="options" v-if="createData.questionFormat=='0' || createData.questionFormat == '1'">
						<!-- 单选 -->
						<div class="optionsBox">
							<div class="optionsBox_item">
								<div class="head">选项</div>
								<div class="cotent">选项内容</div>
								<div class="answer">是否为答案</div>
								<div class="isImg">选项类型</div>
							</div>
							<div class="optionsBox_item" v-for="(item, index) in createData.options" :key="index"
								:style="{ marginBtotom: item.isImage ? '38px' : '20px' }">
								<div class="head">{{ indexToLetter(index) }}</div>
								<div class="cotent">
									<div v-if="!item.isImage">
										<el-input type="textarea" :rows="3" placeholder="请输入文字选项内容" v-model="item.content">
										</el-input>
									</div>
									<div v-else>
										<el-image v-if="createData.options[index].content" style="width:200px; height: auto"
											:src="createData.options[index].content"
											:preview-src-list="[createData.options[index].content]">
										</el-image>
										<ImgCutter @cutDown="optionsCutDown($event, index)" :boxHeight="800" :boxWidth="800"
											:cutWidth="280" :cutHeight="96" :sizeChange="true"></ImgCutter>
									</div>
								</div>
								<div class="answer">
									<el-switch v-model="item.isCorrect" size="medium" @change="optionsChange(index)">
									</el-switch>
								</div>
								<div class="isImg">
									<el-switch v-model="item.isImage" active-text="图片" inactive-text="文字" size="medium"
										@change="isImageChange($event, index)">
									</el-switch>
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="题目答案:" prop="options" v-else>
						<!-- 计算题 填空题 简答题 -->
						<div class="optionsBox">
							<div class="optionsBox_item">
								<div class="cotentdaan">答案内容</div>
								<div class="isImg">选项类型</div>
							</div>
							<div class="optionsBox_item" v-for="(item, index) in createData.options" :key="index"
								:style="{ marginBtotom: item.isImage ? '38px' : '20px' }">
								<div class="cotentdaan">
									<div v-if="!item.isImage">
										<el-input type="textarea" :rows="6" placeholder="请输入文字选项内容" v-model="item.content">
										</el-input>
									</div>
									<div v-else>
										<el-image v-if="createData.options[index].content" style="width:200px; height: auto"
											:src="createData.options[index].content"
											:preview-src-list="[createData.options[index].content]">
										</el-image>
										<ImgCutter @cutDown="optionsCutDown($event, index)" :boxHeight="800" :boxWidth="800"
											:cutWidth="280" :cutHeight="96" :sizeChange="true"></ImgCutter>
									</div>
								</div>
								<div class="isImg">
									<el-switch v-model="item.isImage" active-text="图片" inactive-text="文字" size="medium"
										@change="isImageChange($event, index)">
									</el-switch>
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="题目解析" prop="password">
						<Editor @onChange="onChange" :placeholder="placeholder" :editorHtml="editorHtml"
							v-if="dialogVisible"
							:excludeKeys="['emotion', 'todo', 'fullScreen', 'uploadVideo', 'insertLink', 'insertTable', 'insertVideo']" />
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="u-custom-btn-primary" type="primary" @click="createMenu">确 定</el-button>
				<el-button @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import ImgCutter from 'vue-img-cutter'
import Editor from '@/components/edit'
export default {
	slot: "uMenu",
	components: {
		ImgCutter,
		Editor
	},
	data() {
		return {
			editorHtml: '',
			editorText: '',
			placeholder: `请输入~~~`,
			page: 1,
			size: 10,
			total: 0,
			mode: 'add',
			loading: false,
			dialogVisible: false,
			id: '',
			treeList: [],
			roleList: [],
			questionFormatoptions:[{
				label:"单选",
				value:"0"
			},{
				label:"多选",
				value:"1"
			},{
				label:"计算题",
				value:"2"
			},{
				label:"填空题",
				value:"3"
			},{
				label:"简答题",
				value:"4"
			}],
			createData: {
				id: '',
				subjectCategory: "",//科目分类id，
				chapter_id: "",//章节id
				questionType: false,//题目类型 文字或者图片
				content: "",//题目内容
				questionFormat: '0',// 选项格式，例如 "单选" 或 "多选"  
				options: [{
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}, {
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}, {
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}, {
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}],
				explanation: '',//题目解析
			},
			createRules: {
				subjectCategory: {
					required: true,
					message: '请选择科目',
					trigger: 'change'
				},
				options: {
					required: true,
					message: '请录入题目选项',
					trigger: 'change'
				}
			},
			searchForm: {
				content: '',
				subjectCategory: ''
			},
			tableData: [],
			subject: [],
			isSubjectid: '',
			isSubjectName: '',
			chapterName: "",
			chapter_id: ""
		}
	},
	created() {
		this.getSubject()
		this.chapter_id = this.$route.params._id
		this.isSubjectid = this.$route.params.subject_id
		this.chapterName = this.$route.params.name
	},
	methods: {
		selectSubject(id) {
			let subjectName = ''
			if (this.subject.length > 0) {
				this.subject.map(item => {
					if (item._id == id) {
						subjectName = item.name
					}
				})
				return subjectName
			}
		},
		onChange(html, text) {
			this.editorHtml = html;
			this.createData.explanation = html;
		},
		optionsCutDown(event, index) { //图片选项上传图片
			this.$http.post('/uploadBase64/upload-base64', { base64Image: event.dataURL }).then(res => {
				if (res.code == 1) {
					this.createData.options[index].content = res.data.url
				}
			})
		},
		isImageChange(e, Rowindex) { //是否为图片选项
			this.createData.options[Rowindex].content = ''
			this.createData.options[Rowindex].isImage = e
		},
		questionFormatChange(e) { //当多选切换到单选并且设置了多个答案,默认一个为答案
			
			if (e=='0') {
				this.createData.options=[{
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				},
				{
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				},
				{
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				},
				{
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				},]
			}else if(e == '1'){
				this.createData.options=[{
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				},
				{
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				},
				{
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				},
				{
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				},]
			}else if(e == '2'){
				this.createData.options=[{
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}]
			}else if(e == '3'){
				this.createData.options=[{
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}]
			}else if(e == '4'){
				this.createData.options=[{
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}]
			}else{
				
			}
		},
		optionsChange(Rowindex) { //单选
			if (!this.createData.questionFormat) {
				this.createData.options.map((item, index) => {
					if (Rowindex == index) {
						item.isCorrect = true
					} else {
						item.isCorrect = false
					}
				})
			}
		},
		questionTypeChange(e) {
			this.createData.content = ''
		},
		cutDown(event) { //上传图片题目内容
			this.$http.post('/uploadBase64/upload-base64', { base64Image: event.dataURL }).then(res => {
				if (res.code == 1) {
					this.createData.content = res.data.url
					this.$refs.createForm.content = res.data.url
				}
			})
		},
		searchTable(t) {
			this.page = 1
			if (t === 'search') {
				this.getData()
			} else {
				this.searchForm = {
					content: '',
					subjectCategory: ''
				}
				this.getData()
			}
		},
		edit(e) { //编辑
			this.id = e._id
			this.mode = 'edit'
			this.createData = {
				id: e._id,
				subjectCategory: e.subjectCategory,//科目分类id
				chapter_id:e.chapter_id,//章节id
				questionType: e.questionType,//题目类型 文字或者图片
				content: e.content,//题目内容
				questionFormat: e.questionFormat,// 选项格式，例如 "单选" 或 "多选"  
				options: e.options,
				explanation: e.explanation,//题目解析
			}
			this.$nextTick(() => {
				this.onChange(e.explanation)
			})
			this.dialogVisible = true
		},
		create() { //新增
			this.mode = 'add'
			this.createData = {
				id: '',
				subjectCategory: this.isSubjectid,//科目分类id
				chapter_id: this.chapter_id,//章节id,//章节id
				questionType: false,//题目类型 文字或者图片
				content: "",//题目内容
				questionFormat: '0',// 选项格式，例如 "单选" 或 "多选"  
				options: [{
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}, {
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}, {
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}, {
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}],
				explanation: '',//题目解析
			}
			this.dialogVisible = true
		},
		remove(id) {
			this.$confirm('此操作将永久删除该题目, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let data = {
					id
				}
				this.$http.post('/admin/chapterTopic/delete', data).then(res => {
					if (res.code === 1) {
						this.$message.success('删除成功')
						this.getData()
					}
				})
			})

		},
		resetCreateData() {
			this.createData = {
				id: '',
				subjectCategory: "",//科目分类id
				chapter_id: "",//章节id
				questionType: false,//题目类型 文字或者图片
				content: "",//题目内容
				questionFormat: '0',// 选项格式，例如 "单选" 或 "多选"  
				options: [{
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}, {
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}, {
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}, {
					content: '', // 选项的文本内容   如果选项是图片，则存储图片信息  
					isCorrect: false, // 是否为正确答案 
					isImage: false// 是否为图片
				}],
				explanation: '',//题目解析
			}
			this.$nextTick(() => {
				this.onChange("<p></p>")
				this.$refs.createForm.clearValidate();
			})
		},
		createMenu() {
			if (this.createData.content == '') {
				this.$message.warning("请录入题目内容")
				return
			}
			let options = []
			this.createData.options.map((item, index) => {
				if (item.content == '') {
					options.push(index)
				}
			})
			if (options.length > 0) {
				this.$message.warning("存在选项未录入")
				return
			}
			if (this.createData.questionFormat == '0') { //判断选项里面有没有设置答案
				let answer = false
				this.createData.options.map(item => {
					if (item.isCorrect) {
						answer = true
					}
				})
				if (!answer) {
					this.$message.warning("请录入单选题目答案")
					return
				}
			} else if(this.createData.questionFormat == '1') {
				let answer = 0
				this.createData.options.map(item => {
					if (item.isCorrect) {
						answer += 1
					}
				})
				if (answer == 0) {
					this.$message.warning("请录入多选题目答案")
					return
				}
				if (answer == 1) {
					this.$message.warning("多选题目答案最少为2个")
					return
				}
			}else{
				if(this.createData.options[0].content == ''){
					this.$message.warning("请录入题目答案")
				return
				}
				
			}
			if (this.mode === 'add') {
				this.$refs['createForm'].validate(valid => {
					if (valid) {
						this.createData.classTyep = 'unit'
						this.$http.post('/admin/chapterTopic/create', this.createData).then(res => {
							if (res.code === 1) {
								this.dialogVisible = false
								this.getData()
								this.$message.success("操作成功")
							}
						})
					}
				})
			} else {
				this.$refs['createForm'].validate(valid => {
					if (valid) {
						this.createData.id = this.id
						this.$http.post('/admin/chapterTopic/update', this.createData).then(res => {
							if (res.code === 1) {
								this.dialogVisible = false
								this.getData()
								this.$message.success("操作成功")
							}
						})
					}
				})
			}

		},
		indexToLetter(index) { //将数字转换为大写字母
			// 确保输入是非负整数  
			if (typeof index !== 'number' || !Number.isInteger(index) || index < 0) {
				throw new Error("Index must be a non-negative integer.");
			}

			// 'A'的Unicode码点是65，所以我们将index加上65  
			// 然后使用String.fromCharCode来将Unicode码点转换为字符  
			return String.fromCharCode(index + 65);
		},
		getData() {
			this.loading = true
			this.searchForm.page = this.page
			this.searchForm.classTyep = 'unit'
			let data = {
				...this.searchForm,
				chapter_id:this.chapter_id
			}
			this.$http.get('/admin/chapterTopic/index', {
				params: data
			}).then(res => {
				if (res.code === 1) {
					let tableData = res.data.data
					this.total = res.data.total
					if (tableData.length > 0) {
						tableData.map(item => {
							this.subject.map(key => {
								if (item.subjectCategory == key._id) {
									item.subjectCategoryName = key.name
								}
							})
						})
					}
					this.tableData = tableData
				}
				this.loading = false
			})
		},
		async getSubject() {
			let res = await this.$http.get('/admin/subject/index')
			if (res.code === 1) {
				this.subject = res.data.data
				if (this.subject.length > 0) {
					this.subject.map(item => {
						if (item._id == this.isSubjectid) {
							this.isSubjectName = item.name
						}
					})
				}
			}
			this.getData()
		},
		changePage(e) {
			this.page = e
			this.getData()
		}
	},
	mounted() {
	}
}
</script>

<style lang="scss" scoped>
.page {
	height: calc(100vh - 50px);
	padding: 24px 32px;
	overflow: hidden;
}

.w_400_eliscpe {
	width: 100%;
	height: 80px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.optionsBox {
	width: 100%;

	.optionsBox_item {
		width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		.head {
			width: 30px;
			margin-right: 40px;
			margin-left: 20px;
		}

		.cotent {
			width: 400px;
			margin-right: 40px;
		}
		.cotentdaan{
			width: 700px;
			margin-right: 40px;
		}
		.answer {
			width: 100px;
			margin-right: 40px;
		}

		.isImg {
			width: 150px;
		}
	}
}

.header {
	padding-bottom: 24px;
	border-bottom: solid 1px #DCDFE6;

	.title {
		font-size: 20px;
	}

	.create {
		color: #409EFF;
		font-size: 16px;
		cursor: pointer;
	}
}

.left {
	min-height: calc(100vh - 50px);
	width: 240px;
	background: #fff;
	padding: 10px;

	.el-tree {
		padding-bottom: 20px;
	}
}

.right {
	min-height: calc(100vh - 50px);
	padding: 20px;
	flex: 1;
	overflow: hidden;
}

.el-table {
	margin-top: 24px;

	.o-btn {
		color: #409EFF;
		cursor: pointer;

		~.o-btn {
			margin-left: 12px;
		}
	}
}

.create-form {
	.el-form-item {
		margin-bottom: 20px;
	}
}
</style>
