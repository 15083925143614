<template>
	<div :style="{ width: collapse ? '64px' : '240px' }" class="aside">
		<transition name="sidebarLogoFade">
			<div class="header u-flex u-col-center u-row-center">
				<!-- <img :style="{ marginRight: collapse ? '0' : '12px' }" alt="logo" :src="logo"> -->
				<span v-if="!collapse">{{ title || '答题系统' }}</span>
			</div>
		</transition>
		<el-scrollbar class="u-row-nowrap" style="height: calc(100% - 50px)">
			<el-menu :collapse="collapse" :collapse-transition="false" :default-active="active" :unique-opened="true"
				active-text-color="#fff" background-color="#293449" text-color="#fff" class="menu-box" router>
				<!-- <menu-tree :list="list"></menu-tree> -->
				<template v-for="item in list">
					<!-- <template v-if="item.is_hid === 0"> -->
					<el-submenu v-if="item.child" :index="item.url">
						<template slot="title">
							<i :class="item.icon ? item.icon : 'el-icon-menu'"
								:style="{ visibility: item.icon ? 'visible' : 'hidden' }"></i>
							<span slot="title">{{ item.name }}</span>
						</template>
						<menu-tree :list="item.child"></menu-tree>
					</el-submenu>
					<el-menu-item v-else :index="item.url">
						<i :class="item.icon ? item.icon : 'el-icon-menu'"
							:style="{ visibility: item.icon ? 'visible' : 'hidden' }"></i>
						<span slot="title">{{ item.name }}</span>
					</el-menu-item>
					<!-- </template> -->
				</template>
			</el-menu>
		</el-scrollbar>
	</div>
</template>

<script>
import menuTree from "@/components/menuTree"

export default {
	name: "asideNav",
	components: {
		menuTree,
	},
	data() {
		return {
			active: '',
			title: '',
			logo: '',
			list: [
				{
					name: '轮播图管理',
					url: '/home',
					icon: 'el-icon-search',
					type: 'C',
				},
				{

					name: '题库管理',
					url: '/home',
					icon: 'el-icon-monitor',
					type: 'M',
					child: [
					{
						name: '科目管理',
						url: '/subject',
						type: 'C',
					},
					{
						name: '学校管理',
						url: '/school',
						type: 'C',
					}, 
					{
						name: '模拟练习',
						url: '/simulation',
						type: 'C',
					}, 
					{
						name: '单元测试',
						url: '/unitIndex',
						type: 'C',
					}, 
					{
						name: '真题练习',
						url: '/truequestion',
						type: 'C',
					}
					]
				},

				//  系统管理
				// {
				// 	name: '系统管理',
				// 	url: '/system',
				// 	icon: 'el-icon-setting',
				// 	type: 'M',
				// 	child: [{
				// 		name: '人员管理',
				// 		url: '/user'
				// 	},{
				// 		name: '关于我们',
				// 		url: '/about'
				// 	}]
				// }
			]
		}
	},
	watch: {
		'$route.path'(val) {
			this.setNavBar()
			this.$nextTick(() => {
				this.active = val
			})
		}
	},
	computed: {
		collapse() {
			return this.$store.state.collapse
		}
	},
	created() {
		let role_id =  this.$store.state.userInfo.role  //系统管理员才能看到
		if(role_id == '0'){
			this.list.push({
					name: '系统管理',
					url: '/system',
					icon: 'el-icon-setting',
					type: 'M',
					child: [{
						name: '人员管理',
						url: '/user'
					},{
						name: '关于我们',
						url: '/about'
					}]
			})
		}
		this.list = this._deepClone(this.list)
		this.setMenu(this.list)
		let ishome = this.list.filter(item => {
			return item.url == '/home'
		})

		if (ishome.length == 0) {
			let ischild = this.list.filter(item => {
				return item?.child
			})
			if (ischild.length != 0) {
				this.active = this.list[0].child[0].url
				this.$router.push({ path: this.active })
			} else {
				this.active = this.list[0].url
				this.$router.push({ path: this.active })
			}
		} else {
			this.active = this.$route.path
		}
		this.setNavBar()

		let screenWidth = document.body.clientWidth
		if (screenWidth <= 1200) {
			this.$store.commit('setCollapse', true)
		} else {
			this.$store.commit('setCollapse', false)
		}
	},
	mounted() {
		let that = this
		window.addEventListener('resize', function () {
			return (() => {
				let screenWidth = document.body.clientWidth

				if (screenWidth <= 1200) {
					that.$store.commit('setCollapse', true)
				} else {
					that.$store.commit('setCollapse', false)
				}
			})()
		})
		this.$bus.$on('changeMnue', () => {
			this.list = this._deepClone(this.$store.state.userInfo.tree_role_menu)
			this.setMenu(this.list)
			let ishome = this.list.filter(item => {
				return item.url == '/home'
			})

			if (ishome.length == 0) {
				let ischild = this.list.filter(item => {
					return item?.child
				})
				if (ischild.length != 0) {
					this.active = this.list[0].child[0].url
					this.$router.push({ path: this.active })
				} else {
					this.active = this.list[0].url
					this.$router.push({ path: this.active })
				}
			} else {
				this.active = this.$route.path
			}
			this.setNavBar()

			let screenWidth = document.body.clientWidth
			if (screenWidth <= 1200) {
				this.$store.commit('setCollapse', true)
			} else {
				this.$store.commit('setCollapse', false)
			}
			const web_config = sessionStorage.getItem('web_config') ? sessionStorage.getItem('web_config') : '';
			if (web_config) {
				this.title = JSON.parse(web_config).title
				this.logo = JSON.parse(web_config).site_logo
			} else {
				this.$http.get('backend/web_config').then(res => {
					if (res.code === 1) {
						this.title = res.data.title
						this.logo = res.data.site_logo
					}
				})
			}
		})
	},
	methods: {
		goTo() {
			this.$router.push('/dataVisualization')
		},
		setMenu(arr) {
			arr.map(item => {
				if (item.child) {
					if (item.type === 'C') {
						delete item.child
					} else {
						this.setMenu(item.child)
					}
				}
			})
		},
		setNavBar() {
			if (this.$route.path !== '/' && this.$route.path !== '/home') {
				let list = this.$store.state.$navbar || []
				let flag = list.some(item => item.path === this.$route.path)
				list = flag ? list : [...list, {
					title: this.$route.meta.name,
					path: this.$route.path
				}]
				this.$store.commit('setNavBar', list)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
	transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
	opacity: 0;
}

::v-deep.aside {
	height: 100vh;
	transition: width linear .28s;
	box-shadow: 4px 0 20px 1px rgba(0, 0, 0, 0.08);
	position: relative;
	z-index: 2;
	background-color: #293449;

	.header {
		height: 50px;
		font-weight: bold;
		background-color: #293449;
		color: #fff;
		// border-right: 1px solid #e6e6e6;

		img {
			width: 28px;
			height: 28px;
		}

		span {
			white-space: nowrap;
			font-size: 16px;
			font-weight: bold;
		}
	}

	.el-menu {
		border-right: none;
	}

	.el-menu-item.is-active {
		background-color: #409EFF !important;
	}

	::v-deep.menu-box.el-menu--collapse {
		width: 100%;
		min-height: calc(100vh - 50px);
		border-right: none;


		.el-menu-item:focus,
		.el-menu-item:hover {}

		.el-sub-menu {
			.el-menu-item {
				padding-left: 40px;
				background-color: #F9FAFB !important;

				i {
					width: 24px;
					height: 24px;
					margin-right: 5px;
				}
			}
		}
	}
}
</style>
