<template>
  <div id="app">
    <div  class="u-flex">
      <aside-nav v-if="!hideNav"></aside-nav>
      <div class="content">
        <u-header v-if="!hideNav"></u-header>
        <div :style="{ height: hideNav ? '100vh' : 'calc(100vh - 50px)' }" class="main">
          <el-scrollbar class="u-row-nowrap" style="height: 100%">
            <!-- <keep-alive>
                      <router-view v-if="$route.meta.keepAlive"/>
                    </keep-alive>
                    <router-view v-if="!$route.meta.keepAlive"/> -->
            <transition mode="out-in" name="fade-transform">
              <!-- <keep-alive> -->
              <router-view :key="key" />
              <!-- </keep-alive> -->
            </transition>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
  
</template>
<script>
import asideNav from "@/components/asideNav"
import uHeader from '@/components/uHeader'
export default {
  components: {
    asideNav,
    uHeader
  },
  created() {
    // localStorage.getItem('$state') && this.$store.replaceState(Object.assign(this.$store.state, JSON.parse(
    //     localStorage.getItem('$state'))))
    // localStorage.getItem('$navbar') && this.$store.commit('setNavBar', this.$store.state.$navbar || JSON.parse(
    //     localStorage.getItem('$navbar')) || [])
    // window.onbeforeunload = () => {
    //   localStorage.setItem('$state', JSON.stringify(this.$store.state))
    //   localStorage.setItem('$navbar', JSON.stringify(this.$store.state.$navbar))
    // }

    const web_config = sessionStorage.getItem('web_config') ? sessionStorage.getItem('web_config') : '';
    if (web_config) {
      document.title = JSON.parse(web_config).title
    } else {
      // this.$http.get('/backend/web_config').then(res => {
      //   if(res.code == 1){
      //     document.title = res.data.title
      //     let data = JSON.stringify(res.data)
      //     sessionStorage.setItem('web_config', data)
      //   }
      // })
    }
  },
  data() {
    return {}
  },
  computed: {
    cachedViews() {
      return this.$store.state.$navbar
    },
    key() {
      return this.$route.path
    },
    hideNav() {
      let list = ['/login', '/maintain', '/dataVisualization',"/wxApplet"]
      return list.indexOf(this.$route.path) !== -1
    },
    collapse() {
			return this.$store.state.collapse
      
		}
  }
}
</script>
<style lang="scss">
@import '@/assets/css/common.scss';
</style>
<style lang="scss" scoped>
.content {
  flex: 1;
  overflow: hidden;
}
/deep/.el-table__row td{
  background-color: #FFF !important;
}
.footer{
  width: 100%;
  height: 68px;
  color: #999;
  font-size: 12px;
  display: flex;
  align-items: center;
  p{
    margin-bottom: 10px;
  }
  .footer_left{
    background-color: #293449;
    height: 100%;
  }
  .footer_right{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
