<template>
	<div class="page">
		<div class="big-box">
			<div class="login-box">
				<div class="title">管理系统</div>
				<div class="login-form">
					<div class="login-warp">
						<div class="input-box">
							<input autocomplete="new-password" placeholder="请输入用户名" v-on:keyup.enter="sub" type="text"
								v-model="form.user_name">
						</div>
						<div class="input-box">
							<input :type="t" autocomplete="new-password" placeholder="请输入密码" v-on:keyup.enter="sub"
								v-model="form.password">
							<img alt="" class="eye" src="@/assets/img/eye.png" @click="showPwd" v-show="t == 'text'">
							<img alt="" class="eye" src="@/assets/img/eye1.png" @click="showPwd" v-show="t == 'password'">
						</div>
						<div class="input-box">
							<el-checkbox v-model="isRemenber" size="medium ">记住密码</el-checkbox>
						</div>
						<el-button :loading="loading" class="u-custom-btn-primary u-m-t-40" type="primary"
							v-on:keyup.enter="sub" @click="sub">立即登录</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
export default {
	name: "login",
	data() {
		return {
			t:"password",
			show: false,
			isRemenber: false,
			form: {
				user_name: '',
				password: '',
			},
			loading: false,
		}
	},
	created() {
	},
	methods: {
		sub() {
			if (!this.form.user_name) {
				this.$message.error('登录名不能为空')
				return
			}
			if (!this.form.password) {
				this.$message.error('密码不能为空')
				return
			}
			this.loading = true
			this.$http.post('/admin/users/login', {username:this.form.user_name,password:this.form.password}).then(res => {
				if (res.code === 1) {
					this.$message.success('登录成功')
					this.$store.commit('setToken', res.data.token.token)
					this.$store.dispatch('updateUserInfo')
					// 登录成功后 判断是否选择了勾选密码
					if (this.isRemenber) {
						//添加cookie
						Cookies.set('user_name', this.form.user_name, {
							expires: 30
						})
						//使用crypto-js进行加密（需要npm加载后引入） 并存储到cookie中 此处user123! 为秘钥 
						Cookies.set('password', CryptoJS.AES.encrypt(this.form.password, 'user123!'), {
							expires: 30 // 存储30天
						})
					} else {
						// 删除cookie
						Cookies.remove('user_name')
						Cookies.remove('password')
					}
					setTimeout(() => {
						if (this.$route.query.redirect) {
							this.$router.replace(this.$route.query.redirect)
						} else {
							this.$router.replace('/')
						}
					}, 500)
				}
				this.loading = false
			}).catch(()=>{
				this.loading = false
			})
		},
		showPwd() {
			this.t = this.t === 'password' ? 'text' : 'password'
		}
	}
}
</script>

<style lang="scss" scoped>
.page {
	height: 100vh;
	background: url("../../assets/img/bg.png") no-repeat center/cover;
}
.input-backup{
  position: absolute;
  left: 0;
  pointer-events: none;
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  outline: 0;
  padding: 0 15px;
  font-family: monospace;
  font-size: 16px;
}
.input-original{
	height: 60px;
	::v-deep .el-input__inner{
		height:60px;
	}
	::v-deep .el-input__inner::placeholder{
		font-family: Helvetica Neue;
	}
	::v-deep .el-input__inner::selection{
    color: white;
  }
}
.input-original{
  height: 60px !important;
  color: white;
  caret-color: #606266;
  font-family: monospace;
  &::placeholder {
    font-family: Helvetica Neue;
  }
  &::selection{
    color: white;
  }
}
.w350{
  width: 350px;
}

.big-box {
	background: #fff;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 4px;

	.img-box {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.img-title {
			width: 130px;
			height: 36px;
			background-image: url(../../assets/img/login_bg.png);
			background-size: cover;
			background-repeat: no-repeat;
			color: #F15353;
			line-height: 36px;
			text-align: center;
		}

		.icon-login {
			width: 46px;
			height: 46px;
			cursor: pointer;
		}
	}

	.login-box {
		padding: 54px 70px;

		.title {
			text-align: center;
			font-size: 32px;
			font-family: Source Han Sans CN-Regular, Source Han Sans CN;
			font-weight: 400;
			color: #1A1A1A;
			margin-bottom: 30px;
		}


		.login-header {}

		.login-warp {

			.input-box {
				position: relative;

				/deep/.el-cascader {
					width: 100%;
					height: 60px;

					.el-input {
						width: 100%;
						height: 60px;

						.el-input__inner {
							height: 100%;
							line-height: 60px;
							height: 60px;
						}
					}
				}

				~.input-box {
					margin-top: 24px;
				}

				input {
					width: 500px;
					height: 60px;
					border: solid 1px rgba(151, 151, 151, .25);
					border-radius: 4px;
					line-height: 60px;
					padding: 0 40px 0 16px;
					font-size: 16px;
				}

				input::placeholder {
					color: #b3b3b3;
				}



				.pre-icon {
					position: absolute;
					left: 12px;
					top: 50%;
					transform: translateY(-50%);
					width: 30px;
					height: 30px;
				}

				.eye {
					position: absolute;
					right: 14px;
					top: 21px;
					cursor: pointer;
				}

				.code-img {
					position: absolute;
					right: 12px;
					top: 8px;
					cursor: pointer;
					width: 180px;
					height: 44px;
					background-color: #f7f7f7;
					line-height: 44px;
					text-align: center;
				}
			}

			.forget {
				color: #409EFF;
				margin-top: 12px;
				cursor: pointer;
			}

			.el-button {
				width: 280px;
				height: 64px;
				margin: 16px auto;
				display: block;
			}
		}
	}
}
</style>
