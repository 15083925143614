<template>
	<div class="page">
		<div class="header u-flex u-row-between u-col-center">
			<span class="title">轮播图管理</span>
		</div>
		<div class="u-flex u-m-t-24 u-m-b-24">
			<el-button icon="el-icon-plus" size="small" type="primary" @click="create">新增</el-button>
		</div>
		<el-table :data="tableData" v-loading="tabloading" style="width: 100%" header-row-class-name="header-table">
			<el-table-column label="序号" align="center" width="80">
				<template slot-scope="scope">
					{{ scope.row.slot }}
				</template>
			</el-table-column>
			<el-table-column label="图片" prop="name" align="center">
				<template slot-scope="scope">
					<el-image  :src="scope.row.priceUrl" :preview-src-list="[scope.row.priceUrl]"
						style="width: 388px; height: 132px;">
					</el-image>
				</template>
			</el-table-column>
			<el-table-column label="备注" align="center" width="200">
				<template slot-scope="scope">
					{{ scope.row.remake }}
				</template>
			</el-table-column>
			<el-table-column label="操作" width="150" fixed="right">
				<template slot-scope="scope">
					<span class="o-btn" @click="edit(scope.row)">编辑</span>
					<span class="o-btn o-btn-red" @click="remove(scope.row._id)">删除</span>
				</template>
			</el-table-column>
		</el-table>
		<!-- 修改编辑弹窗 -->
		<el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" destroy-on-close
			:title="mode === 'add' ? '新增轮播图' : '编辑轮播图'" width="600px" >
			<div>
				<el-form ref="createForm" :model="createData" :rules="createRules" label-width="80px">
					<el-form-item label="图片上传" prop="priceUrl">
						<el-upload class="avatar-uploader" v-loading="imageDloading" :action="imageUrl"
							:show-file-list="false" :on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload" :on-error="beforeAvatarerror">
							<img v-if="imageUrls" :src="imageUrls" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
						<el-image v-if="imageUrls" :src="require('@/assets/img/eye.png')"
							:preview-src-list="[imageUrls]" style="width: 25px; height: 22px;margin-top: 20px;">
						</el-image>
					</el-form-item>
					<el-form-item label="备注" prop="remake">
						<el-input placeholder="请输入备注内容" v-model="createData.remake" clearable>
						</el-input>
					</el-form-item>
					<el-form-item label="排序" prop="slot">
						<el-input-number v-model="createData.slot" :min="0" :precision="0" size="small">
						</el-input-number>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="affirmCreate">确 定</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
import imageUrl from '../../utils/upload'
export default {
	name: "examine",
	data() {
		return {
			tabloading: false,
			imageUrls: '',
			imageUrl: '',
			tableData: [],
			dialogVisible: false,
			createData: {
				slot: '',
				priceUrl: '',
				remake: '',
			},
			createRules: {
				priceUrl: [{
					required: true,
					message: '请上传图片',
					trigger: ['blur', 'change']
				}]
			},
			searchForm: {},
			mode: 'add',
			id: '',
			imageDloading: false
		}
	},
	created() {
		this.getData()
		this.imageUrl = imageUrl
	},
	methods: {
		handleAvatarSuccess(res, file) {
			this.imageDloading = false
			this.imageUrls = res.data.url
			this.createData.priceUrl = res.data.url
		},
		beforeAvatarUpload(file) {
			console.log(file);
			this.imageDloading = true
		},
		beforeAvatarerror() {
			this.imageDloading = false
		},
		edit(e) {
			console.log(e);
			this.mode = 'edit'
			this.id = e._id
			for (let k in e) {
				this.createData.hasOwnProperty(k) ? this.createData[k] = e[k] : ''
				this.imageUrls = e.priceUrl
			}
			this.dialogVisible = true
		},
		create() {
			this.mode = 'add'
			this.createData.priceUrl = ''
			this.imageUrls = ''
			this.createData.slot = 0
			this.createData.remake = ''
			this.dialogVisible = true
		},
		affirmCreate() {
			this.$refs.createForm.validate(e => {
				if (e) {
					let url = this.mode === 'add' ? '/admin/swiper/create' : '/admin/swiper/update'
					this.mode === 'edit' ? this.createData.id = this.id : ''
					this.$http.post(url, this.createData).then(res => {
						if (res.code === 1) {
							this.getData()
							this.dialogVisible = false
						}
					})
				}
			})
		},
		getData() {
			this.tabloading = true
			this.$http.get('/admin/swiper/index').then(res => {
				if (res.code === 1) {
					this.tableData = res.data
				}
			})
			this.tabloading = false
		},
		resetCreateData() {
			this.$nextTick(() => {
				this.$refs.createForm.clearValidate();
			})
		},
		remove(id) {
			this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let data = {
					id
				}
				this.$http.post('/admin/swiper/delete', data).then(res => {
					if (res.code === 1) {
						let i = this.tableData.findIndex(item => item.id === id)
						this.tableData.splice(i, 1)
						this.$message.success('操作成功')
					}
				})
			}).catch()
		},
		changePage(p) {
			this.page = p
			this.getData()
		}
	}
}
</script>

<style lang="scss" scoped>
.page {
	padding: 24px 32px;
}

.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409EFF;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}

.avatar {
	width: 388px;
	height: 132px;
	display: inline-block;
}

.header {
	padding-bottom: 24px;
	border-bottom: solid 1px #DCDFE6;

	.title {
		font-size: 20px;
	}

	.create {
		color: #409EFF;
		font-size: 16px;
		cursor: pointer;
	}
}

.search-form {
	padding: 32px 0 20px;

	.el-form-item {
		margin-bottom: 12px;
	}
}

::v-deep.el-table {
	thead tr>th:first-child .cell {
		padding: 0 14px !important;
	}

	.total {
		color: #FF4949;
	}

	.o-btn {
		color: #409EFF;
		cursor: pointer;

		~.o-btn {
			margin-left: 16px;
		}
	}
}
</style>
