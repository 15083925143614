<template>
    <div class="w_editor">
        <div class="w_editor_box">
            <!-- 工具栏 -->
            <Toolbar class="w_editor_tool" :editor="editor" :defaultConfig="toolbarConfig" />
            <!-- 编辑器 -->
            <Editor class="w_editor_txt" :defaultConfig="editorConfig" v-model="html" @onChange="onChange"
                @onCreated="onCreated" @customPaste="customPaste" @onMaxLength="onMaxLength" />
        </div>
    </div>
</template>
  
<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { DomEditor } from '@wangeditor/editor'
import axios from 'axios';
let that;
const toolbarKeys = [
    // //背景色,引用,粗体,无序列表,清除格式,行内代码,代码块,文字颜色,减少缩进,分割线,表情,字体,字号,全屏,标题
    // 'bgColor','blockquote','bold','bulletedList','clearStyle','code','codeBlock','color','delIndent','divider','emotion','fontFamily','fontSize','fullScreen','headerSelect',
    // //增加缩进,网络图片,插入链接,插入表格,插入视频,居中对齐,两端对齐,左对齐,右对齐,行高,有序列表,重做,
    // 'indent','insertImage','insertLink','insertTable','insertVideo','italic','justifyCenter','justifyJustify','justifyLeft','justifyRight','lineHeight','numberedList','redo',
    //下标,上标,删除线,待办,下划线,撤销,上传视频,上传图片
    // 'sub','sup','through','todo','underline','undo','uploadVideo',
    'justifyLeft','italic','justifyCenter','uploadImage'
];

export default {
    name: 'editor',
    components: { Editor, Toolbar },
    props: { toolbarKeys: { type: Array, default: () => { return [] } }, excludeKeys: { type: Array, default: () => { return [] } }, placeholder: { type: String, default: '' }, editorHtml: { type: String, default: '' } },
    data(e) {
        return {
            editor: null,
            html: '',
            toolbarConfig: {
                //显示菜单
                toolbarKeys: [],
                //隐藏菜单
                excludeKeys: [], //'fullScreen','uploadImage','uploadVideo','insertImage','insertLink','insertTable','insertVideo'
            },
            editorConfig: {
                // placeholder: '请输入内容...',
                // autoFocus: false,
                maxLength: 15000,
                MENU_CONF: {
                    'uploadImage': {
                        // 自定义上传
                        async customUpload(file, insertFn) {
                            // that在这里使用 
                            const formData = new FormData();
                            formData.append('file', file);
                            try {
                                axios.post('/uploadFile/upload', formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                        // 可能还需要其他头部信息，如认证令牌等  
                                    }
                                })
                                    .then(response => {
                                        // 处理服务器响应  
                                        // 通常，你需要从响应中提取上传后的文件 URL  
                                        // 并将其设置为默认的图片 URL  
                                        insertFn(response.data.url);
                                    })
                                    .catch(error => {
                                        // 处理错误  

                                    });
                            } catch (error) {
                                console.error('Upload error:', error);
                            }
                        }
                    },
                }
            }
        }
    },
    methods: {
        //创建编辑器
        onCreated(editor) {
            this.editor = Object.seal(editor);
            this.initToolbar();
        },
        //初始化Toolbar
        initToolbar() {
            this.$nextTick(() => {
                let editor = this.editor;
                let editorConfig = editor.getConfig();
                let toolbar = DomEditor.getToolbar(editor);
                let toolbarConfig = toolbar.getConfig();
                //设置placeholder
                let placeholder = this.placeholder.length > 0 ? this.placeholder : '请输入内容...';
                editorConfig.placeholder = placeholder;
                //设置显示菜单
                let insertKeys = this.toolbarKeys.length > 0 ? this.toolbarKeys : toolbarKeys;
                toolbarConfig.insertKeys = { index: 0, keys: insertKeys };
                //设置隐藏菜单
                if (this.excludeKeys.length > 0) { toolbarConfig.excludeKeys = this.excludeKeys; }
                //设置初始内容
                if (this.editorHtml.length > 0) { this.html = this.editorHtml; }
            });
        },
        // onChange 时获取编辑器最新内容
        onChange(editor) {  this.$emit('onChange', editor.getHtml(), editor.getText()); },
        //长度溢出限制
        onMaxLength(editor) { },
        // 用户自定义粘贴
        customPaste(editor, event, callback) {
            // event.preventDefault();
            let writedText = editor.getText() || '';
            let text = event.clipboardData.getData('text/plain') || '';
            //这里有个BUG   一次性粘贴内容超过this.editorConfig.maxLength的长度  也可以粘贴进去  目前没找到解决方法  需要在提交的时候验证一下editor.getText()长度
            if (text.length > this.editorConfig.maxLength || writedText.length + text.length > this.editorConfig.maxLength) return this.$message.warning(`文字超出${this.editorConfig.maxLength}字符`);
            return false;
        },
        //禁用
        disableHandler() {
            const editor = this.editor;
            if (!editor) return;
            editor.disable();
        },
        //上传图片成功回调
        uploadSuccess(data, id) {
            const editor = this.editor;
            if (!editor) return;
            const node = { type: 'image', src: data.previewUrl, alt: data.fileName, children: [{ text: '' }] } //children: [ { text: '' } ]参数必须有，不然报错
            editor.insertNode(node);
            // editor.dangerouslyInsertHtml(`<img src="${data.previewUrl}" alt="${data.fileName}" data-href="" style=""/>`);// 强行插入html方法
        },
    },
    created() { that = this; },
    mounted() { },
    beforeDestroy() {
        const editor = this.editor;
        if (!editor) return;
        editor.destroy();
    },
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.w_editor {
    height: 600px;

    .w_editor_box {
        border: 1px solid #ccc;
        border-radius: 8px;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }

    .w_editor_tool {
        // border: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 8px 8px 0 0;
    }

    .w_editor_txt {
        flex-grow: 1;
        overflow-y: hidden;
        border-radius: 0 0 8px 8px;
        box-sizing: border-box;
    }

    .editor_upload {
        display: none;
    }
}
</style>
<style>
.w_editor_box .w_editor_tool .w-e-toolbar {
    border-radius: 8px 8px 0 0;
}

.w_editor_box .w_editor_txt .w-e-text-container {
    /* background: #F6F7F9; */
}

.w_editor_box .w_editor_txt .w-e-text-placeholder {
    font-style: normal;
    color: #999;
    font-size: 14px;
}
</style>
  
  