<template>
	<div class="page">
		<div class="header u-flex u-row-between u-col-center">
			<span class="title">关于我们</span>
		</div>
		<Editor @onChange="onChange" :placeholder="placeholder" :editorHtml="editorHtml" v-if="show"
			:excludeKeys="['emotion', 'todo', 'fullScreen', 'uploadVideo', 'insertLink', 'insertTable', 'insertVideo']" />
		<el-button class="u-custom-btn-primary u-m-t-40" type="primary" v-on:keyup.enter="sub"
			@click="submit">保存</el-button>
	</div>
</template>

<script>
import Editor from '@/components/edit'
export default {
	name: "about",
	components: {
		Editor
	},
	data() {
		return {
			editorHtml: '',
			editorText: '',
			placeholder: `请输入~~~`,
			show:true
		}
	},
	created() {
		this.getInfo()
	},
	methods: {
		onChange(html, text) {
			this.editorHtml = html;
		},
		getInfo() {
			this.show = false
			this.$http.get('/admin/about/index').then(res => {
				if (res.data.length > 0) {
					let data = res.data[0].content
					this.editorHtml = data
					this.show = true
					this.$nextTick(() => {
						this.onChange(data)
					})
				}
			}).catch(err=>{
				this.show = true
			})
		},
		submit() {
			let data = {
				content: this.editorHtml
			}
			this.$http.post('/admin/about/createOrUpdate', data).then(res => {
				this.$message.success('操作成功')
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.page {
	padding: 24px 32px;
}

.header {
	padding-bottom: 24px;
	border-bottom: solid 1px #DCDFE6;

	.title {
		font-size: 20px;
	}

	.create {
		color: #409EFF;
		font-size: 16px;
		cursor: pointer;
	}
}
</style>
