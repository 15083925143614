import axios from "axios"
import { Message } from 'element-ui'
import store from "@/store"
import VueRouter from "vue-router"
import router from '@/router'
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '' : 'https://www.zhenyvshuati.com'
// axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '' : 'http://127.0.0.1:3000'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token')
    if (config?.config?.noHead){
        delete config.headers['Authorization']
        return config
    } 
    token ? config.headers['Authorization'] = token : ''
    return config
}, function (error) {
    Message.error('网络异常')
    return Promise.reject(error)
})
axios.interceptors.response.use(function (response) {
    let data = response.data
    let code = Number(data.code)
    data.code = code
    if(code === 1){
        return data
    }else if(code == 401){  //token过期
		Message.closeAll()
        Message.error({
            message:data.msg,
            duration:4000
        })
        store.commit('logout')
        setTimeout(()=>{
			router.push('/login')
        },1000)
        return Promise.reject('error')
    }else{
        Message.error(data.msg)
        return data
    }
}, function (error) {
    return Promise.reject(error)
})
export default axios