<template>
    <div class="page">
        <div class="header u-flex u-row-between u-col-center">
            <span class="title">章节管理</span>
        </div>
        <div class="u-flex u-m-t-24 u-m-b-24" style="justify-content: space-between;">
            <el-form :model="searchForm" class="search-form" inline>
                <el-form-item label="章节名称：">
                    <el-input v-model="searchForm.name" placeholder="请输入章节名称" ></el-input>
                </el-form-item>
                <el-form-item label="选择科目:">
                    <el-select v-model="searchForm.subject_id" placeholder="请选择科目" >
                        <el-option v-for="item in subjectList" :key="item._id" :label="item.name" :value="item._id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="u-custom-btn-primary" icon="el-icon-search"  type="primary"
                        @click="searchTable('search')">搜索
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain  type="primary"
                        @click="searchTable('reset')">重置
                    </el-button>
                </el-form-item>
            </el-form>
            <div class="operate-box u-flex u-col-center">
                <el-button class="u-custom-btn-primary" icon="el-icon-plus"  type="primary" @click="create">新增
                </el-button>
            </div>
        </div>
        <el-table :data="tableData" style="width: 100%;" v-loading="tabLoading" header-row-class-name="header-table">
            <el-table-column label="序号" align="center" prop="slot" width="200px">
            </el-table-column>
            <el-table-column label="章节名称" prop="name" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column label="所属科目" prop="subject_id" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                    <div>{{ selectSubject(scope.row.subject_id) }}</div>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column label="操作" width="180" fixed="right">
                <template slot-scope="scope">
                    <span class="o-btn" @click="mangeTopic(scope.row)">管理题库</span>
                    <span class="o-btn" @click="edit(scope.row)">编辑</span>
                    <span class="o-btn o-btn-red" @click="remove(scope.row._id)">删除</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="u-flex u-row-end u-p-t-32 u-p-b-24">
            <el-pagination @current-change="changePage" :current-page="page" :page-size="size"
                layout="total, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
        <!-- 修改编辑弹窗 -->
        <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" destroy-on-close
            :title="mode === 'add' ? '新增章节' : '编辑章节'" width="400px" @closed="resetCreateData" top="30vh">
            <div>
                <el-form ref="createForm" :model="createData" :rules="createRules" label-width="80px">
                    <el-form-item label="章节名称" prop="name">
                        <el-input v-model="createData.name" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="所属科目" prop="subject_id">
                        <el-select v-model="createData.subject_id" filterable placeholder="请选择" size="small"
                            style="width: 100%;">
                            <el-option v-for="item in subjectList" :key="item._id" :label="item.name" :value="item._id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="排序" prop="slot">
                        <el-input-number v-model="createData.slot" :min="0" :precision="0" size="small">
                        </el-input-number>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="affirmCreate">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: "examine",
    data() {
        return {
            page: 1,
            total: 0,
            size: 10,
            tableData: [],
            dialogVisible: false,
            createData: {
                name: '',
                subject_id: '',
                slot: 0
            },
            createRules: {
                name: [{
                    required: true,
                    message: '学科名称不能为空',
                    trigger: ['blur', 'change']
                }],
                subject_id: [
                    {
                        required: true,
                        message: '所属科目不能为空',
                        trigger: ['blur', 'change']
                    }
                ]
            },
            searchForm: {
                name: "",
                subject_id:''
            },
            subjectList: [],
            mode: 'add',
            id: '',
            tabLoading: false
        }
    },
    created() {
        this.getData()
        this.getSubject()
    },
    methods: {
        mangeTopic(row) {
            this.$router.push({ name: "unit", params: { ...row } })
        },
        selectSubject(id) {
            let subjectName = ''
            if (this.subjectList.length > 0) {
                this.subjectList.map(item => {
                    if (item._id == id) {
                        subjectName = item.name
                    }
                })
                return subjectName
            }
        },
        searchTable(t) {
            this.page = 1
            if (t === 'search') {
                this.getData()
            } else {
                this.searchForm = {
                    name: '',
                    subject_id:''
                }
                this.getData()
            }
        },
        edit(e) {
            this.mode = 'edit'
            this.id = e._id
            this.createData.slot = e.slot
            this.createData.name = e.name
            this.createData.subject_id = e.subject_id
            this.dialogVisible = true
        },
        create() {
            this.mode = 'add'
            this.createData = {
                name: '',
                slot: 0,
                subject_id: ''
            }
            this.dialogVisible = true
        },
        affirmCreate() {
            this.$refs.createForm.validate(e => {
                if (e) {
                    let url = this.mode === 'add' ? '/admin/chapter/create' : '/admin/chapter/update'
                    this.mode === 'edit' ? this.createData.id = this.id : ''
                    this.$http.post(url, this.createData).then(res => {
                        if (res.code === 1) {
                            this.getData()
                            this.$message.success('操作成功')
                            this.dialogVisible = false
                        }
                    })
                }
            })
        },
        getSubject() {
            let data = {}
            this.$http.get('/api/subject/index', {
                params: data
            }).then(res => {
                if (res.code === 1) {
                    this.subjectList = res.data
                }
            })
        },
        getData() {
            this.tabLoading = true
            let data = {
                name: this.searchForm.name,
                subject_id:this.searchForm.subject_id,
                page: this.page
            }
            this.$http.get('/admin/chapter/index', {
                params: data
            }).then(res => {
                if (res.code === 1) {
                    this.total = res.data.total
                    this.tableData = res.data.data
                }
            })
            this.tabLoading = false
        },
        resetCreateData() {
            this.createData = {
                name: '',
                slot: 0,
                subject_id: ''
            }
            this.$nextTick(() => {
                this.$refs.createForm.clearValidate();
            })
        },
        remove(id) {
            this.$confirm('此操作将永久删除该章节, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id
                }
                this.$http.post('/admin/chapter/delete', data).then(res => {
                    if (res.code === 1) {
                        this.getData()
                        this.$message.success('操作成功')
                    }
                })
            }).catch()
        },
        changePage(p) {
            this.page = p
            this.getData()
        }
    }
}
</script>

<style lang="scss" scoped>
.page {
    padding: 24px 32px;
}

.header {
    padding-bottom: 24px;
    border-bottom: solid 1px #DCDFE6;

    .title {
        font-size: 20px;
    }

    .create {
        color: #409EFF;
        font-size: 16px;
        cursor: pointer;
    }
}

.search-form {
    padding: 32px 0 20px;

    .el-form-item {
        margin-bottom: 12px;
    }
}

::v-deep.el-table {
    thead tr>th:first-child .cell {
        padding: 0 14px !important;
    }

    .total {
        color: #FF4949;
    }

    .o-btn {
        color: #409EFF;
        cursor: pointer;

        ~.o-btn {
            margin-left: 16px;
        }
    }
}
</style>
