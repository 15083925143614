import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import mixin from './utils/mixin'
Vue.mixin(mixin)
import axios from "./utils/request"
Vue.prototype.$http = axios

// import CKEditor from '@ckeditor/ckeditor5-vue2';
// Vue.use( CKEditor );
import * as echarts from "echarts"
Vue.prototype.$echarts = echarts

import treeSelect from "@/components/treeSelect"
Vue.component('treeSelect',treeSelect)

import qs from 'qs'
Vue.prototype.$qs = qs
Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
